<template>
  <div id="preview">
    <div class="inner">
      <iframe style="width: 100%; height: 1120px;" src="https://ditto.fm/raymond-take-care-of-eleanor"></iframe>
      <!-- <div class="image fit">
        <img src="images/police-state.jpg" alt="Police State by Shutterwax" />
      </div> -->
      <div class="content">
        <header>
          <h2>Raymond, Take Care of Eleanor</h2>
        </header>
        <iframe style="border: 0; width: 100%; height: 42px;" src="https://bandcamp.com/EmbeddedPlayer/track=3937662597/size=small/bgcol=ffffff/linkcol=0687f5/transparent=true/" seamless><a href="https://shutterwax.bandcamp.com/track/raymond-take-care-of-eleanor">Raymond, Take Care of Eleanor by Shutterwax</a></iframe>
        <p>Oh Raymond...</p>
      </div>
    </div>
    <router-link :to="{ name: 'Breathing', params: {} }" class="nav previous">
      <span class="fa fa-chevron-left"></span>
    </router-link>
    <router-link :to="{ name: 'Mark', params: {} }" class="nav next">
      <span class="fa fa-chevron-right"></span>
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'RaymondPage',
  components: {

  },
  mounted() {

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
